<template>
  <div class="lf-tracks">
    <div class="lf-tracks__form">
      <b>Выбор трека</b>
      <Dropdown
        header="Образовательный трек (Приоритет 1)"
        placeholder="Выберите из списка"
        v-model="formData.first_direction_id"
        :error="v$.formData.first_direction_id?.$errors?.length !== 0"
        :keys="
          currentDirectionsWithValidations?.map((direction) => direction.title)
        "
        :items="
          currentDirectionsWithValidations?.map((direction) => direction.id)
        "
        :disabled="isDisabled"
        @update:modelValue="
          formData.second_direction_id = '';
          formData.third_direction_id = '';
          clearSimilarField('first_direction_id', 'direction');
        "
      />
      <Dropdown
        v-if="currentDirectionsWithValidations.length > 1"
        header="Образовательный трек (Приоритет 2)"
        placeholder="Выберите из списка"
        v-model="formData.second_direction_id"
        :error="v$.formData.second_direction_id?.$errors?.length !== 0"
        :disabled="isDisabled || !formData.first_direction_id"
        :keys="
          currentDirectionsWithValidations
            ?.filter((direction) => {
              return direction.id !== formData.first_direction_id;
            })
            .map((direction) => direction.title)
        "
        :items="
          currentDirectionsWithValidations
            ?.filter((direction) => {
              return direction.id !== formData.first_direction_id;
            })
            .map((direction) => direction.id)
        "
        @update:modelValue="
          formData.third_direction_id = '';
          clearSimilarField('second_direction_id', 'direction');
        "
      />
      <Dropdown
        v-if="currentDirectionsWithValidations.length > 2"
        header="Образовательный трек (Приоритет 3)"
        placeholder="Выберите из списка"
        v-model="formData.third_direction_id"
        :error="v$.formData.third_direction_id?.$errors?.length !== 0"
        :disabled="isDisabled || !formData.second_direction_id"
        :keys="
          currentDirectionsWithValidations
            ?.filter((direction) => {
              return direction.id !== formData.first_direction_id;
            })
            .filter((direction) => {
              return direction.id !== formData.second_direction_id;
            })
            .map((item) => {
              return item.title;
            })
        "
        :items="
          currentDirectionsWithValidations
            ?.filter((direction) => {
              return direction.id !== formData.first_direction_id;
            })
            .filter((direction) => {
              return direction.id !== formData.second_direction_id;
            })
            .map((direction) => {
              return direction.id;
            })
        "
        @update:modelValue="
          clearSimilarField('third_direction_id', 'direction')
        "
      />
      <b>Выбор команды</b>
      <Dropdown
        header="Команда (Приоритет 1)"
        placeholder="Выберите из списка"
        v-model="formData.first_team"
        :error="v$.formData.first_team?.$errors?.length !== 0"
        :keys="teams?.keys?.map((team) => team.title)"
        :items="teams?.keys?.map((team) => team.id)"
        :disabled="isDisabled"
        @update:modelValue="
          formData.second_team = '';
          formData.third_team = '';
          clearSimilarField('first_team', 'team');
        "
      />
      <Dropdown
        header="Команда (Приоритет 2)"
        placeholder="Выберите из списка"
        v-model="formData.second_team"
        :error="v$.formData.second_team?.$errors?.length !== 0"
        :disabled="isDisabled || !formData.first_team"
        :keys="
          teams?.keys
            ?.filter((team) => {
              return team.id !== formData.first_team;
            })
            .map((team) => team.title)
        "
        :items="
          teams?.keys
            ?.filter((team) => {
              return team.id !== formData.first_team;
            })
            .map((team) => team.id)
        "
        @update:modelValue="
          formData.third_team = '';
          clearSimilarField('second_team', 'team');
        "
      />
      <Dropdown
        header="Команда (Приоритет 3)"
        placeholder="Выберите из списка"
        v-model="formData.third_team"
        :error="v$.formData.third_team?.$errors?.length !== 0"
        :disabled="isDisabled || !formData.second_team"
        :keys="
          teams?.keys
            ?.filter((team) => {
              return team.id !== formData.first_team;
            })
            .filter((team) => {
              return team.id !== formData.second_team;
            })
            .map((item) => {
              return item.title;
            })
        "
        :items="
          teams?.keys
            ?.filter((team) => {
              return team.id !== formData.first_team;
            })
            .filter((team) => {
              return team.id !== formData.second_team;
            })
            .map((team) => {
              return team.id;
            })
        "
        @update:modelValue="clearSimilarField('third_team', 'team')"
      />
      <b>Описание команд</b>
      <div class="lf-tracks__teams">
        <a
          :href="team.url_tg"
          target="_blank"
          v-for="team in teams?.info?.data"
        >
          <img :src="team.logo" alt="" />
          <span>{{ team.title }}</span>
        </a>
      </div>
    </div>
    <div class="lf-tracks__btn" v-if="!isDisabled">
      <Button @click="saveInfo" :disabled="isBtnDisabled">
        Сохранить изменения
      </Button>
    </div>
  </div>
</template>
<script>
import Dropdown from "@/components/Blocks/Dropdown.vue";
import Button from "@/components/Blocks/Button.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import validations from "@/enums/validations";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import moment from "moment";
import { clearShiftStatementData } from "@/store/mutations";

export default {
  name: "LigaForumTracks",
  components: { Button, Dropdown },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      formData: {
        first_direction_id: "",
        second_direction_id: "",
        third_direction_id: "",
        first_team: "",
        second_team: "",
        third_team: "",
      },
    };
  },
  props: ["type", "currentProject"],
  validations() {
    if (this.currentDirectionsWithValidations.length === 1) {
      validations.tracksLF.second_direction_id.required = {};
      validations.tracksLF.third_direction_id.required = {};
    } else if (this.currentDirectionsWithValidations.length === 2) {
      validations.tracksLF.third_direction_id.required = {};
    }
    return {
      formData: validations.tracksLF,
    };
  },
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.tracksLF;
    },
    currentDirections() {
      return this.directions?.info
        ?.find(
          (category) =>
            category.liga_category_id ===
            (this.currentCategoryId ||
              this.currentCategoryData?.info_category.id)
        )
        ?.info_directions?.data?.map((direction) => {
          return { id: direction.id, title: direction.title };
        });
    },
    currentDirectionsWithValidations() {
      let buffer = this.currentDirections.map((direction) => {
        const validation = this.directions.validation?.find(
          (validationRule) => {
            return (
              direction.id === validationRule.direction_id &&
              validationRule.type_ee ===
                this.user.info_educational_establishment?.type?.key &&
              validationRule.region_ids?.length > 0
            );
          }
        );
        return validation ? { ...direction, validation } : direction;
      });
      buffer = buffer?.filter((dir) => {
        dir.inArray = false;
        if ("validation" in dir) {
          dir.inArray =
            dir?.validation?.type_ee ===
              this.user.info_educational_establishment?.type?.key &&
            dir?.validation?.region_ids?.includes(
              this.user.education_region_id
            );
        }
        return dir.inArray;
      });
      return buffer;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    isDisabled() {
      let boolean;
      if (Object.values(this.currentCategoryData).length > 0) {
        if (this.validPeriodForRollback) {
          if (this.currentCategoryData?.status?.key === 0) {
            boolean = false;
          } else boolean = this.currentCategoryData?.status?.key !== 35;
        } else if (this.validPeriodFor35Status) {
          boolean = this.currentCategoryData?.status?.key !== 35;
        } else return true;
      }
      return this.isOldApplication || boolean;
    },
    isBtnDisabled() {
      if (this.currentDirectionsWithValidations.length === 1) {
        delete this.formData.second_direction_id;
        delete this.formData.third_direction_id;
      } else if (this.currentDirectionsWithValidations.length === 2) {
        delete this.formData.third_direction_id;
      }
      return Object.values(this.formData).some((item) => !item);
    },
    ...mapState("user", ["user"]),
    ...mapState([
      "storage",
      "currentCategoryData",
      "statementId",
      "currentCategoryId",
      "isOldApplication",
      "firstShiftStatementData",
      "secondShiftStatementData",
      "thirdShiftStatementData",
    ]),
    ...mapGetters(["directions", "teams"]),
  },
  methods: {
    async loadInfo() {
      const shiftData = `${this.type.toLowerCase()}ShiftStatementData`;
      const thisShiftData = this[shiftData];

      if (
        this.currentCategoryData?.status?.key &&
        this.currentCategoryData?.status?.key !== 0
      ) {
        this.setShiftStatementData({
          key: shiftData,
          form: this.currentCategoryData?.info_liga_forum,
        });
      }

      if (this.currentDirectionsWithValidations.length === 1) {
        delete this.formData.second_direction_id;
        delete this.formData.third_direction_id;
        delete this.firstShiftStatementData.second_direction_id;
        delete this.firstShiftStatementData.third_direction_id;
      } else if (this.currentDirectionsWithValidations.length === 2) {
        delete this.firstShiftStatementData.third_direction_id;
      }

      Object.keys(this.formData).forEach((item) => {
        if (thisShiftData[item]) this.formData[item] = thisShiftData[item];
      });
    },
    async saveInfo() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }
      this.setShiftStatementData({
        key: `${this.type.toLowerCase()}ShiftStatementData`,
        form: this.formData,
      });
      this.$store.commit("user/setDisabledData", [
        `isLF${this.type}ShiftTracksFilled`,
        true,
      ]);
      await this.$router.push({ name: `LigaForum${this.type}ShiftInfo` });
    },
    clearSimilarField(value, type) {
      let directions = [
        "first_direction_id",
        "second_direction_id",
        "third_direction_id",
      ];
      let teams = ["first_team", "second_team", "third_team"];
      const arr =
        type === "direction"
          ? directions.filter((direction) => direction !== value)
          : teams.filter((team) => team !== value);

      arr.forEach((item) => {
        if (this.formData[item] === this.formData[value])
          this.formData[item] = "";
      });
    },
    ...mapMutations(["setShiftStatementData"]),
  },
  mounted() {
    this.loadInfo();
    this.$store.commit("user/setDisabledData", [
      `isLF${this.type}ShiftTracksFilled`,
      Object.values(this.formData).every((item) => item),
    ]);
  },
};
</script>

<style lang="scss">
.lf-tracks {
  &__form {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    max-width: 728px;
  }
  &__teams {
    display: flex;
    grid-gap: 25px;
    justify-content: center;
    align-items: center;
    a {
      cursor: pointer;
      img {
        height: 100px;
        aspect-ratio: 1 / 1;
        width: 100%;
        object-fit: contain;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        img {
          transform: scale(1.3);
        }
      }
      span {
        display: block;
        margin-top: 10px;
        text-align: center;
        width: 100%;
        font-size: 20px;
        color: var(--input--placeholder);
      }
    }
  }
  &__btn {
    margin-top: 15px;
  }
  @include adaptive(tablet-small) {
    &__teams {
      flex-wrap: wrap;
    }
  }
}
</style>
